import React, { useState, useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SelectPicker, Loader } from "rsuite";
import InputLabel from "../../components/form/InputLabel";
import { debounce } from "lodash";

const InfintyDropdown = ({
  attrs: { value, disabled = false, searchParam, searchId, payload },
  label,
  name,
  request,
  endpoint,
  formatter,
  valueField,
  labelField,
  options,
  requiredMark,
  error,
  setValue,
  field,
}) => {
  const [page, setPage] = useState(0);
  const [dropDownData, setDropdownData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const customRenderMenuItem = (value, obj, menu) => {
    return (
      <div
        className="p-4 text-xs space-y-2 border-b-2 cursor-pointer hover:bg-red-500 hover:text-white"
        style={{ width: "100%" }}
        onClick={(e) => menu.props.onSelect(obj?.id, obj, e)}
      >
        <div className="flex items-center justify-between">
          <div className="font-bold text-sm">{value}</div>
        </div>
        <div className="flex items-center justify-between">
          {obj?.state && (
            <div>
              State: <span className="font-medium">{obj.state}</span>
            </div>
          )}
          {obj?.city && (
            <div>
              City: <span className="font-medium">{obj.city}</span>
            </div>
          )}
        </div>
      </div>
    );
  };
  const defaultFormatter = useCallback(
    (option) => {
      if (!option) {
        return {};
      }
      if (formatter) {
        return formatter(option);
      }
      return {
        value: String(option[valueField]),
        label: String(option[labelField]),
        ...option,
      };
    },
    [valueField, labelField]
  );
  const fetchData = async (pg, id) => {
    const currentPage = pg !== undefined ? pg : page;

    if (pg === 0) {
      setPage(0);
    }
    setLoading(true);
    try {
      let data;
      if (payload) {
        data = {
          page: currentPage,
          size: 15,
          [searchParam]: search,
          id: id || "",
          ...payload,
        };
      } else {
        data = {
          page: currentPage,
          size: 15,
          [searchParam]: search,
          id: id || "",
        };
      }

      const res = await request(endpoint, data);

      if (res?.data?.content) {
        let newArray = [];
        if (page === 0) {
          newArray = res.data.content.map((obj) => defaultFormatter(obj));
          setDropdownData(newArray);
        } else {
          res.data.content.forEach((obj) => {
            const exists = dropDownData.some((item) => item.id === obj.id);
            if (!exists) {
              newArray.push(defaultFormatter(obj));
            }
          });
          setDropdownData([...dropDownData, ...newArray]);
        }
        setCount(res.data.total ?? 0); // Ensure count is set to 0 if total is null/undefined
      } else {
        console.warn("No content data available");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getSearch = useCallback(debounce(fetchData, 1000), [search]);

  useEffect(() => {
    if (request && searchId) {
      fetchData(0, searchId);
    } else if (request) {
      fetchData();
    } else if (options) {
      const updated = options.map((item) => defaultFormatter(item));
      setDropdownData(updated);
    }
  }, [request, options, endpoint, searchId, payload?.stateId]);

  useEffect(() => {
    if (page === 0) return;
    fetchData();
  }, [page]);

  useEffect(() => {
    if (search?.length > 2) getSearch(0);
    return getSearch.cancel;
  }, [search, getSearch]);
  return (
    <div className="mb-5 mt-1">
      <InputLabel label={label} requiredMark={requiredMark} />
      <div className="mt-2 relative">
        <SelectPicker
          data={dropDownData || []}
          searchable={true}
          size="lg"
          name={name}
          value={`${value}`}
          style={{ width: "100%" }}
          placeholder="Select"
          loading={loading && !search}
          onSelect={(value, item) => {
            setValue({ [field]: value }, item);
          }}
          onClean={() => {
            setValue({ [field]: null });
            // setId("");
          }}
          onSearch={(value) => setSearch(value)}
          renderMenu={(menu) => {
            return (
              <InfiniteScroll
                dataLength={dropDownData?.length || 0}
                next={() => setPage((prev) => prev + 1)}
                hasMore={count !== dropDownData?.length}
                loader={
                  loading && (
                    <h4 className="text-center">
                      <Loader size="xs" speed="fast" />
                    </h4>
                  )
                }
                height={menu?.props?.data?.length ? 270 : 50}
                scrollableTarget="scrollableDiv"
                className="flex flex-col items-center"
              >
                {menu?.props?.data?.length ? (
                  menu?.props?.data?.map((item, index) =>
                    customRenderMenuItem(item.label, item, menu)
                  )
                ) : loading ? (
                  ""
                ) : (
                  <div className="text-center">No data found</div>
                )}
              </InfiniteScroll>
            );
          }}
        />
        {error ? <p className="mt-2 text-xs text-red-500">{error}</p> : false}
      </div>
    </div>
  );
};

export default InfintyDropdown;
