import React, { useCallback, useMemo, useState } from "react";
import Button from "../../components/Button";
import { inputTypes } from "./inputTypes";
import { postApiClaim } from "../../lib/AppHelper";

export const BankDetailsForm = ({
  step,
  members,
  prevStep,
  setValue,
  onSubmit,
  value,
  state,
  isMobile,
}) => {
  const [errors, setErrors] = useState({});

  const columns = useMemo(
    () => [
      {
        field: "bankId",
        label: "Bank name",
        type: "infintydropdown",
        labelField: "bankName",
        valueField: "id",
        options: state?.banks,
        requiredMark: true,
        visible: true,
        endpoint: "searchBanks",
        request: postApiClaim,
        searchParam: "bankName",
        searchId: state?.bankId,
      },
      {
        field: "otherBankName",
        label: "Other bank Name",
        type: "text",
        requiredMark: true,
        placeholder: "Enter bank name",
        visible: ({ values }) => {
          return values?.bankId === 34 ? true : false;
        },
      },
      {
        field: "accountName",
        label: "Account holder name",
        requiredMark: true,
        visible: true,
      },
      {
        field: "accountNumber",
        label: "Account number",
        requiredMark: true,
        visible: true,
      },
      {
        field: "ifscCode",
        label: "IFSC code",
        requiredMark: true,
        visible: true,
      },
    ],
    [state]
  );

  const errorCheck = useCallback(
    (field, visible) => {
      let error;
      if (typeof visible === "function") {
        visible = visible({ values: state });
      }
      if (visible) {
        if (!state[field] || (state[field] && state[field].length < 1)) {
          error = true;
          setErrors((errors) => ({
            ...errors,
            [field]: "Please Fill this field",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [field]: void 0,
          }));
        }
      } else {
        setErrors((errors) => ({
          ...errors,
          [field]: void 0,
        }));
      }

      return error;
    },
    [state]
  );

  const onClick = useCallback(() => {
    let main = false;
    let error = false;
    columns.map((column) => {
      const { field, visible = true, required = true } = column;
      if (required) error = errorCheck(field, visible);
      if (error) {
        main = true;
      }
    });
    if (!main) return onSubmit();
  }, [columns, onSubmit, errorCheck]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          padding: isMobile ? "0px 0px" : "0px 30px",
        }}
      >
        {columns.map((column, index) => {
          const {
            label,
            field,
            requiredMark,
            type = "text",
            visible,
            options,
            request,
            endpoint,
            searchParam,
            searchId,
            ...rest
          } = column;

          let RenderComponent = inputTypes[type];

          const isVisible =
            typeof visible === "function"
              ? visible({ values: state })
              : visible;
          if (!isVisible) {
            return null;
          }

          let fieldOptions = options;
          if (type === "dropdown") {
            fieldOptions =
              field === "bankName" ? state?.banks || [] : options || [];
          }

          return (
            <div style={{ width: "350px" }} key={index}>
              <RenderComponent
                label={label}
                requiredMark={requiredMark}
                error={[errors[field]]}
                state={value}
                options={fieldOptions}
                field={field}
                setValue={(data, item) => {
                  setValue(data);
                  if (data?.bankId) {
                    setValue({
                      bankName: item.bankName,
                    });
                  }
                  if (data?.bankName === "other") {
                    setValue({
                      otherBankName: item?.name || null,
                    });
                  }
                  setErrors((errors) => ({
                    ...errors,
                    [field]: void 0,
                  }));
                }}
                attrs={{
                  onChange: (e) => {
                    if (field === "accountNumber") {
                      setValue({
                        [field]: e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 16),
                      });
                    } else if (field === "accountName") {
                      setValue({
                        [field]: e.target.value
                          .replace(/[^a-zA-Z ]/g, "")
                          .slice(0, 34),
                      });
                    } else if (field === "otherBankName") {
                      setValue({
                        [field]: e.target.value
                          .replace(/[^a-zA-Z ]/g, "")
                          .slice(0, 30),
                      });
                    } else if (field === "ifscCode") {
                      setValue({
                        [field]: e.target.value
                          .replace(/[^a-zA-Z0-9]/g, "")
                          .slice(0, 11),
                      });
                    } else {
                      setValue({ [field]: e.target.value });
                    }
                    setErrors((errors) => ({
                      ...errors,
                      [field]: void 0,
                    }));
                  },
                  onBlur: (e) => {
                    errorCheck(field);
                  },
                  value: state[field],
                  searchParam: searchParam,
                  searchId: searchId,
                }}
                request={request}
                endpoint={endpoint}
                {...rest}
              />
            </div>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <button
          onClick={prevStep}
          className="flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500 border border-2 border-red-500"
        >
          Back
        </button>
        <div>
          <Button
            attrs={{
              type: "button",
              onClick,
            }}
            options={members}
            size="md"
            className={"mt-5"}
            title={"Continue"}
          />
        </div>
      </div>
    </div>
  );
};
