import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import Button from "../../components/Button";
import { inputTypes } from "./inputTypes";
import { postApiClaim } from "../../lib/AppHelper";

export const HospitalizationReasonForm = ({
  step,
  members,
  prevStep,
  setValue,
  onSubmit,
  value,
  state,
  isMobile,
}) => {
  const columns = [
    {
      field: "hospitalId",
      label: "Name of the hospital",
      type: "infintydropdown",
      requiredMark: true,
      labelField: "hospitalName",
      valueField: "id",
      endpoint: "searchHospitals",
      request: postApiClaim,
      searchParam: "hospitalName",
      searchId: state.hospitalId,
      options: state?.hospitals,
      visible: ({ values }) => {
        let allowedProductSpecifications = ["INTERNAL", "HOSPI CASH"];
        return allowedProductSpecifications.includes(
          `${values?.claimType}`.toUpperCase()
        );
      },
    },
    {
      field: "hospitalName",
      label: "Hospital name",
      requiredMark: true,
      type: "text",
      placeholder: "Enter hospital name",
      visible: ({ values }) => {
        let allowedProductSpecifications = ["INTERNAL", "HOSPI CASH"];
        return values?.hospitalId === "6666" &&
          allowedProductSpecifications.includes(
            `${values?.claimType}`.toUpperCase()
          )
          ? true
          : false;
      },
    },
    {
      field: "hospitalState",
      label: "State",
      type: "dropdown",
      labelField: "name",
      valueField: "id",
      requiredMark: true,
      options: state?.states,
      visible: ({ values }) => {
        let allowedProductSpecifications = ["INTERNAL", "HOSPI CASH"];
        return values?.hospitalId &&
          allowedProductSpecifications.includes(
            `${values?.claimType}`.toUpperCase()
          )
          ? true
          : false;
      },
    },
    {
      field: "hospitalCity",
      label: "City",
      type: "infintydropdown",
      labelField: "name",
      valueField: "id",
      requiredMark: true,
      endpoint: "searchCities",
      request: postApiClaim,
      searchParam: "cityName",
      searchId: state.hospitalCity,
      options: state?.cities,
      payload: {
        stateId: state?.hospitalState || "",
      },
      disableField: ({ values }) => {
        return !values.hospitalState;
      },
      visible: ({ values }) => {
        let allowedProductSpecifications = ["INTERNAL", "HOSPI CASH"];
        return values?.hospitalId &&
          values.hospitalState &&
          allowedProductSpecifications.includes(
            `${values?.claimType}`.toUpperCase()
          )
          ? true
          : false;
      },
    },
    {
      field: "claimStartDate",
      label: "Date of admission",
      requiredMark: true,
      formate: "dd-MM-yyyy",
      type: "date",
      disabledDateFunc: (date, state) => {
        if (
          date < moment(state?.policyStartDate, "DD-MM-YYYY").toDate() ||
          date > new Date()
        ) {
          return true;
        }
      },
      attrs: {
        value: state?.claimStartDate,
      },
      visible: ({ values }) => {
        let allowedProductSpecifications = ["INTERNAL", "HOSPI CASH"];
        return allowedProductSpecifications.includes(
          values?.productSpecification
        );
      },
      disableField: ({ values }) => {
        return !values.hospitalState;
      },
    },
    {
      field: "claimEndDate",
      label: "Date of discharge",
      requiredMark: true,
      formate: "dd-MM-yyyy",
      type: "date",
      disabledDateFunc: (date, state) => {
        const date1 = new Date(moment(date).startOf("day").toDate());
        const date2 = new Date(
          moment(state?.claimStartDate).startOf("day").toDate()
        );
        if (
          date < new Date(state?.claimStartDate) ||
          date > new Date() ||
          date1.getTime() === date2.getTime()
        ) {
          return true;
        }
      },
      visible: ({ values }) => {
        let allowedProductSpecifications = ["INTERNAL", "HOSPI CASH"];
        return allowedProductSpecifications.includes(
          values?.productSpecification
        );
      },
      disableField: ({ values }) => {
        return values?.claimStartDate ? false : true;
      },
    },

    {
      field: "reason",
      label: "Reason for hospitalization/diagnosis",
      requiredMark: true,
      visible: ({ values }) => {
        let allowedProductSpecifications = ["INTERNAL", "HOSPI CASH"];
        return allowedProductSpecifications.includes(
          `${values?.claimType}`.toUpperCase()
        );
      },
    },
    {
      field: "dateOfaccident",
      label: "Date of accident",
      formate: "dd-MM-yyyy",
      requiredMark: true,
      type: "date",
      disabledDateFunc: (date, state) => {
        if (
          date < moment(state?.policyStartDate, "DD-MM-YYYY").toDate() ||
          date > new Date()
        ) {
          return true;
        }
      },
      visible: ({ values }) => {
        let allowedProductSpecifications = ["ACCIDENTAL"];
        return allowedProductSpecifications.includes(
          `${values?.claimType}`.toUpperCase()
        );
      },
    },
    {
      field: "accidentCause",
      label: "Cause of accident",
      requiredMark: true,
      type: "textarea",
      visible: ({ values }) => {
        return values?.isPatientDied;
      },
    },
    {
      field: "dateOfDeath",
      label: "Date of demise",
      requiredMark: true,
      formate: "dd-MM-yyyy",
      type: "date",
      disabledDateFunc: (date, state) => {
        if (
          date > new Date() ||
          date < new Date(state?.dateOfaccident) ||
          date < moment(state?.policyStartDate, "DD-MM-YYYY").toDate()
        ) {
          return true;
        }
      },
      visible: ({ values }) => {
        return `${values?.claimType}`.toUpperCase() === "CRITICAL LIFE COVER" ||
          values?.isPatientDied === true
          ? true
          : false;
      },
      disableField: ({ values }) => {
        let allowedProductSpecifications = ["CRITICAL LIFE COVER"];
        return (
          !allowedProductSpecifications.includes(
            `${values?.claimType}`.toUpperCase()
          ) && !values.dateOfaccident
        );
      },
    },

    {
      field: "deathCause",
      label: "Cause of death",
      requiredMark: true,
      type: "textarea",
      visible: ({ values }) => {
        return `${values?.claimType}`.toUpperCase() === "CRITICAL LIFE COVER"
          ? true
          : false;
      },
    },
    {
      field: "isPatientDied",
      label: "Has the patient passed away?",
      type: "toggle",
      leftLabel: "NO",
      rightLabel: "YES",
      required: false,
      visible: ({ values }) => {
        let allowedProductSpecifications = ["ACCIDENTAL"];
        return allowedProductSpecifications.includes(
          `${values?.claimType}`.toUpperCase()
        );
      },
    },
  ];

  const [errors, setErrors] = useState({});

  const errorCheck = useCallback(
    (field, visible) => {
      let error;
      if (typeof visible === "function") {
        visible = visible({ values: state });
      }
      if (visible) {
        if (!state[field] || (state[field] && state[field].length < 1)) {
          error = true;
          setErrors((errors) => {
            return {
              ...errors,
              [field]: "Please Fill this field",
            };
          });
        } else {
          setErrors((errors) => {
            return {
              ...errors,
              [field]: void 0,
            };
          });
        }
      } else {
        setErrors((errors) => {
          return {
            ...errors,
            [field]: void 0,
          };
        });
      }
      return error;
    },
    [state]
  );

  const onClick = useCallback(() => {
    let main = false;
    let error = false;
    columns.map((column) => {
      const { field, visible = true, required = true } = column;
      if (required) error = errorCheck(field, visible);
      if (error) {
        main = true;
      }
    });

    if (!main) return onSubmit();
  }, [columns, onSubmit, errorCheck]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          padding: isMobile ? "0px 0px" : "0px 30px",
        }}
      >
        {columns.map((column, index) => {
          let {
            label,
            field,
            requiredMark,
            type = "text",
            visible,
            placeholder,
            max,
            formate,
            disableField,
            options,
            request,
            endpoint,
            searchParam,
            searchId,
            payload,
            ...rest
          } = column;
          let disabled = false;
          let RenderComponent = inputTypes[type];

          if (typeof visible === "function") {
            visible = visible({ values: state });
          }
          if (!visible) {
            return null;
          }

          if (typeof disableField === "function") {
            disabled = disableField({ values: state });
          }

          let fieldOptions = options;
          if (type === "dropdown") {
            fieldOptions =
              field === "hospitalState"
                ? state?.states || []
                : field === "hospitalId"
                ? state?.hospitals || []
                : field === "hospitalCity"
                ? state?.cities || []
                : options || [];
          }

          return (
            <div style={{ width: "350px" }} key={index}>
              <RenderComponent
                label={label}
                requiredMark={requiredMark}
                error={[errors[field]]}
                state={state}
                options={fieldOptions}
                field={field}
                setValue={(date, item) => {
                  if (field === "hospitalId") {
                    setValue({
                      hospitalId: item?.value,
                      hospitalState: item?.stateId || null,
                      hospitalCity: item?.cityId || null,
                    });
                  } else if (field === "hospitalState") {
                    setValue({
                      hospitalState: item?.value || null,
                      hospitalCity: "",
                    });
                  } else if (type === "date") {
                    setValue({
                      [field]:
                        date === null
                          ? null
                          : moment(date).format("YYYY-MM-DD"),
                    });
                  } else {
                    setValue(date);
                  }

                  setErrors((errors) => ({
                    ...errors,
                    [field]: void 0,
                  }));
                }}
                attrs={{
                  onChange: (e) => {
                    setValue({
                      [field]:
                        field === "isPatientDied"
                          ? e
                          : field === "reason"
                          ? e.target.value.slice(0, 100)
                          : e.target.value,
                    });
                    setErrors((errors) => ({
                      ...errors,
                      [field]: void 0,
                    }));
                  },
                  onBlur: (e) => {
                    errorCheck(field);
                  },
                  value: state[field],
                  disabled: disabled,
                  searchParam: searchParam,
                  searchId: searchId,
                  payload: payload,
                }}
                request={request}
                endpoint={endpoint}
                {...rest}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <button
          onClick={prevStep}
          className="flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500 border border-2 border-red-500"
        >
          Back
        </button>
        <div>
          <Button
            attrs={{
              type: "button",
              onClick,
            }}
            options={members}
            size="md"
            className={"mt-5"}
            title={"Continue"}
          />
        </div>
      </div>
    </div>
  );
};
