import { PlusCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { DocumentView } from "../../components/AppComponents";
import { InputFile } from "../../components/form/InputFile";
import InputLabel from "../../components/form/InputLabel";
import Button from "../../components/Button";

export const FileInput = ({
  docID,
  label,
  error,
  setValue,
  field,
  attrs,
  onRemove,
  details,
  isShowStatus = true,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <InputFile
        error={error}
        label={label}
        placeholder={
          <div className="text-center items-center flex-col w-32 inline-flex">
            <PlusCircleIcon className="text-primary" width={24} />
            <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
              <p dangerouslySetInnerHTML={{ __html: details }} />
              <br />
              Max 15 MB
            </p>
          </div>
        }
        attrs={{
          ...attrs,
          accept: "application/pdf, image/*",
          capture: "environment",
          multiple: true,
          // value: void 0,
          // onChange: handleFileChange,
        }}
      />

      {attrs?.value && attrs?.value?.length
        ? attrs?.value?.map((item, index) => {
            return (
              <div className="mb-5 ml-5 " key={index}>
                <InputLabel label={`File ${index + 1}`} />

                <div className="mt-2  relative">
                  {item.status === "PENDING" && (
                    <div
                      onClick={() =>
                        onRemove && onRemove(field, item.id, index)
                      }
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        backgroundColor: "#f8f8f8",
                        width: 25,
                        height: 25,
                        borderRadius: 15,
                        right: -10,
                        top: -10,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        boxShadow:
                          "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                      }}
                    >
                      X
                    </div>
                  )}
                </div>
              </div>
            );
          })
        : void 0}
    </div>
  );
};
