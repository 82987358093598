import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { ApiGet, downloadExcelFile } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import { Pagination } from "rsuite";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { RefreshIcon } from "@heroicons/react/solid";
import { InputSelect } from "../../components/form/InputSelect";

export class ResellerUploadTeamDataReport extends Component {
  MessageRef = React.createRef();
  state = {
    reports: [],
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      source: "",
      employee_id: "",
      page: 1,
    },
    employees: [],
    sourceData: [
      {
        value: "",
        label: "All",
      },
      {
        value: "API",
        label: "API",
      },
      {
        value: "Excel",
        label: "Excel",
      },
      {
        value: "WEB RESELLER",
        label: "WEB RESELLER",
      },
      {
        value: "AIQA CENTER RESELLER",
        label: "AIQA CENTER RESELLER",
      },
    ],
    toMaxDate: new Date(),
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    params["source"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, employees, sourceData } = this.state;
    const { user } = this.props;
    const { permissions } = user;
    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.upload_records_by_source ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/"> Back </AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "20%" }} className="float-right">
                  <Button
                    attrs={{ type: "button", onClick: this.downloadClick }}
                    size="md"
                    title={"Download"}
                  />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-5 gap-4">
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "employee_id")}
                  value={params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">Me</option>
                  {employees.map((option, index) => {
                    return (
                      <option value={option.id} key={index}>
                        {option.name} ({option.mobile})
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <InputSelect
                  requiredMark=""
                  value={params.source}
                  attrs={{
                    value: params.source,
                    onChange: (e) => this.onChangeHandler(e, "source"),
                  }}
                  name="source"
                  label="Source Type"
                  options={sourceData}
                />
              </div>
              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>
              <div
                className="flex flex-row"
                style={{ maxWidth: "50%", paddingTop: "22px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="md"
                  title={"Search"}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    {reports ? (
                      <table className="min-w-full" id="table-to-xls">
                        <thead>
                          <tr>
                            <td>
                              <table className="min-w-full border">
                                <tbody>
                                  <tr>
                                    <td>
                                      <table className="min-w-full">
                                        <thead className="border-b">
                                          <tr>
                                            <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                              Name
                                            </th>
                                            <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                              Subscription Count
                                            </th>
                                            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                              Subscription Amount
                                            </th>
                                            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                              Unpaid Count
                                            </th>
                                            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                              Unpaid Amount
                                            </th>
                                            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                              Total
                                            </th>
                                            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                              Source
                                            </th>
                                            {/* <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                              Created On
                                            </th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {reports.map((item, index) => {
                                            return (
                                              <tr
                                                className="border-b"
                                                key={index}
                                              >
                                                <td
                                                  className="text-sm  font-bigger px-6 py-4 whitespace-nowrap capitalize"
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {item?.name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                  <p className=" ml-10">
                                                    {item.paid_policy
                                                      ? item.paid_policy
                                                      : 0}
                                                  </p>
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                  {item.paid_amount
                                                    ? item.paid_amount
                                                    : 0}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                  {item.unpaid_policy
                                                    ? item.unpaid_policy
                                                    : 0}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                  {item.unpaid_amount
                                                    ? item.unpaid_amount
                                                    : 0}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                  {item.count ? item.count : 0}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                  {item.source
                                                    ? item.source
                                                    : "Unknown"}
                                                </td>
                                                {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                  {item?.created_on
                                                    ? moment(
                                                        item?.created_on
                                                      ).format(
                                                        "DD-MM-YYYY h:mm A"
                                                      )
                                                    : "-"}
                                                </td> */}
                                              </tr>
                                            );
                                          })}
                                          {reports.length === 0 ? (
                                            <tr>
                                              <td
                                                colSpan="9"
                                                height="50"
                                                className="text-red-500 font-light text-center"
                                              >
                                                Your result is empty
                                              </td>
                                            </tr>
                                          ) : (
                                            false
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </thead>
                      </table>
                    ) : (
                      false
                    )}
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={2}
                        size="xs"
                        layout={this.state.layout}
                        total={this.state.total}
                        limit={this.state.limit}
                        activePage={this.state.params.page}
                        onChangePage={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>
                You do not have permission to view Upload Date Team Report
                Source
              </h4>
            </div>
          </div>
        )}
      </Main>
    );
  }

  componentDidMount() {
    this.getReports(this.state.params);
    this.getTeam();
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  handleClick = () => {
    const { params } = this.state;
    params["page"] = 1;
    this.setState({ params: params });
    this.getReports(this.state.params);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  getReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/upload-report-by-source", request);
    if (response.status === "success") {
      if (response.data) {
        const data = response.data;
        this.setState({ reports: data.items, total: data.total });
        if (this.state.params.all == true) {
          this.setState({ layout: ["total"] });
          this.setState({ limit: response.data.total });
        } else {
          this.setState({ layout: ["total", "-", "pager", "skip"] });
          this.setState({ limit: 10 });
        }
      } else {
        this.state.reports = [];
      }
    } else {
      this.MessageRef.current.show(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  downloadClick = () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      reportType: "download",
      employee_id: this.state.params.employee_id,
    };
    this.DownloadReports(payload);
  };

  DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    await downloadExcelFile(
      "report/upload-report-by-source",
      request,
      "Report.xlsx"
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const ResellerUploadTeamDataReportConnect = connect(mapStateToProps)(
  (props) => {
    return <ResellerUploadTeamDataReport {...props} />;
  }
);

export default withRouter(ResellerUploadTeamDataReportConnect);
