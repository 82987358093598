import React, { Component, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import {
  ApiGet,
  setError,
  downloadPDFFile,
  PythonCareApiGet,
} from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import Payment from "./application/Payment";
import VpaMandate from "./application/VpaMandate";
import Main from "./Main";
import { Modal, Panel, PanelGroup } from "rsuite";
import cardLogo from "../images/cardLogo.png";
import { MemberIconWithName } from "../components/MemberIconWithName";
import moment from "moment";
import { Search } from "./Search";
import { toast } from "react-toastify";
import AddMember from "../utils/AddMember";

const ExpandableComponent = ({
  member,
  policyId,
  bookAppointmentData,
  eligibleForClaim,
  start_date,
  end_date,
  product_id,
  componentDidMount,
  data,
}) => {
  const [expanded, setExpanded] = useState(false);
  const permissions = useSelector((state) => state?.user?.permissions);

  return (
    <Panel
      key={member.id}
      header={
        <MemberIconWithName
          member={member}
          bookAppointmentData={bookAppointmentData}
          setExpanded={setExpanded}
          expanded={expanded}
          policyId={policyId}
          product_id={product_id}
          start_date={start_date}
          end_date={end_date}
          eligibleForClaim={eligibleForClaim}
          claimAdd={permissions && permissions.claim_add ? true : false}
          aiqaCenter={
            (permissions && permissions.aiqa_center) ||
            permissions?.appointment_booking
              ? true
              : false
          }
          componentDidMount={componentDidMount}
          data={data}
        />
      }
      expanded={expanded}
      id={"panel" + member.id}
      defaultExpanded="false"
    >
      {expanded ? "" : null}
    </Panel>
  );
};
export class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: null,
      bookAppointmentData: {},
      filterValues: "",
      open: false,
    };
  }

  getSubscriptionData(filterValues) {
    if (filterValues && filterValues.search) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      (async () => {
        const res = await ApiGet("subscriptions", {
          ...filterValues,
        });
        if (res?.data?.results?.length) {
          this.props.history.push({
            pathname: "/subscriptions",
            state: res?.data?.results,
          });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          AppStore.dispatch({ type: "LOADING", loading: false });
          toast.error("No member found !");
        }
      })();
    }
  }

  MessageRef = React.createRef();
  render() {
    const { application, bookAppointmentData, filterValues, open } = this.state;
    const { permissions } = this.props.user;

    return (
      <Main MessageRef={this.MessageRef}>
        <div className="flex flex-row">
          <div>
            <AppBackButton to="/">Back to Dashboard</AppBackButton>
          </div>
          <div
            style={{ float: "right", marginLeft: "auto", marginRight: "15px" }}
          >
            {application?.product_reseller &&
            application?.product_reseller.invoice_on_reg === "Y" ? (
              <button
                className="w-full shadow whitespace-nowrap items-center justify-center ml-2 px-2 py-2 border border-transparent text-base rounded-xl text-white bg-primary hover:bg-primary-700 active:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-gray-200 disabled:text-gray-400"
                onClick={() =>
                  this.generatePdf(this.props.match.params.appId, "invoice")
                }
              >
                Download Invoice
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        {(permissions?.aiqa_center || permissions?.center_coordinator) &&
          !application?.status && (
            <div className="w-full mt-3 mb-3">
              <Search
                setValue={(value) => {
                  this.setState({ ...this.state, filterValues: value });
                  this.getSubscriptionData(value);
                }}
                filterValues={filterValues}
              />
            </div>
          )}

        <div className="flex flex-col md:flex-row space-x-0 md:space-x-5 space-y-5 md:space-y-0 px-2 space-y-6 customer-detail">
          <div className="flex-auto">
            {application &&
            !application?.insured_member_id &&
            application?.status !== 1 ? (
              <>
                {application?.product_type === "mutual_aid" ? (
                  <VpaMandate
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                    MessageRef={this.MessageRef}
                    application={application}
                  />
                ) : (
                  <Payment
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                    MessageRef={this.MessageRef}
                    application={application}
                  />
                )}
              </>
            ) : (
              <>
                {application?.customer_name && (
                  <>
                    <Link
                      to={`/product/${application?.product_id}`}
                      id="medicalCard"
                      className="bg-primary flip-front p-4 text-white rounded-lg member-card w-96 max-w-full flex flex-col justify-between"
                    >
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "3px",
                          }}
                        >
                          <p className="text-lg pb-8">
                            {application?.product_display_name
                              ? application?.product_display_name
                              : application?.product_name}
                          </p>
                        </div>
                        <img
                          style={{ height: "28px", width: "120px" }}
                          src={cardLogo}
                          className="w-24 h-24"
                          alt="card"
                        />
                      </div>

                      <div>
                        <p className="text-white capitalize">
                          {application?.customer_name?.toLowerCase()}
                        </p>
                        <div className="flex space-x-20">
                          <div
                            className="text-right tracking-widest"
                            style={{ letterSpacing: "4px" }}
                          >
                            {application?.insured_member_id}
                          </div>
                        </div>

                        <div className="flex space-x-32 pt-5">
                          <div>
                            <p className="text-sm text-primary-200 mb-1">
                              Activated on
                            </p>
                            <p className="text-sm">
                              {application?.start_date &&
                                moment(application?.start_date).format(
                                  "DD-MM-YYYY"
                                )}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-primary-200 mb-1">
                              Valid till
                            </p>
                            {/* <p className="text-sm">{application?.policy_details.expire_on}</p> */}
                            <p className="text-sm">
                              {application?.end_date &&
                                moment(application?.end_date).format(
                                  "DD-MM-YYYY"
                                )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="flex justify-between items-center mt-10 mb-5">
                      <AppHeading>Covered Members</AppHeading>
                      {/* <button
                    className="text-white bg-primary-500 p-2 rounded-xl"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={() => this.handleAddMember()}
                  >
                    Add Member
                  </button> */}
                    </div>
                    <PanelGroup accordion bordered defaultExpanded="false">
                      {application?.members.map((member, index) => {
                        return (
                          <ExpandableComponent
                            key={index}
                            member={member}
                            policyId={application?.policy_id}
                            eligibleForClaim={application?.eligible_for_claim}
                            bookAppointmentData={bookAppointmentData}
                            start_date={application?.start_date}
                            end_date={application?.end_date}
                            product_id={application?.product_id}
                            componentDidMount={() => this.componentDidMount()}
                            data={application}
                          />
                        );
                      })}
                    </PanelGroup>
                    <Modal
                      open={open}
                      onClose={this.handleClose}
                      className="sm:w-80 md:w-80 lg:w-1/3"
                    >
                      <Modal.Header>
                        <AppHeading>Add Member</AppHeading>
                      </Modal.Header>
                      <Modal.Body>
                        <AddMember application={application} />
                      </Modal.Body>
                    </Modal>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Main>
    );
  }

  async componentDidMount() {
    const id = this.props.match.params.appId;
    await this.getApplication(id);
  }

  getApplication = async (id) => {
    const { user } = this.props;
    const { permissions } = user;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`subscription/${id}`);
    if (response.status === "success") {
      this.setState({ application: response.data });
      if (response?.data?.members && response?.data?.members[0]?.mobile) {
        let payload = {
          search: response.data.members[0].mobile,
          application_id: id,
        };
        if (
          (permissions?.aiqa_center || permissions?.appointment_booking) &&
          !moment(response.data?.end_date).isBefore()
        ) {
          const data = await PythonCareApiGet("eclinic-search/", payload);
          if (data?.data?.book_appointment && data.data.book_appointment) {
            this.setState({ bookAppointmentData: data.data });
          }
        }
      }
    } else {
      await setError(response.message);
      this.props.history.push("/");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  async generatePdf(appId) {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response2 = await downloadPDFFile(
      `enrollment-service/invoice/getInvoice/${appId}/true`
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  }

  onError = (error) => {
    setError(error, () => {
      this.props.history.replace("/subscriptions");
    });
  };

  onSuccess = async (message) => {
    await this.getApplication(this.state.application?.id);
    this.MessageRef.current?.show(message, "success");
  };

  handleAddMember = (application) => {
    this.setState({ ...this.state, open: true });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const ApplicationConnect = connect(mapStateToProps)((props) => {
  return <Application {...props} />;
});

export default withRouter(ApplicationConnect);
