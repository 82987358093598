import React, { Component } from "react";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Main from "../Main";
import Button from "../../components/Button";
import {
  XIcon,
  TrashIcon,
  ChevronRightIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import * as XLSX from "xlsx";
import { AppStore } from "../../lib/AppStore";
import {
  AbclHeaders,
  AckoHeaders,
  CareHeaders,
  LibertyHeaders,
} from "./Headers";
import FileSaver from "file-saver";
import {
  excelPolicyUploader,
  getReportingEmail,
  getResellerId,
  getVendorCodeBenefitJava,
  getVendorCodesJava,
  setError,
  setMessage,
  validateEmail,
} from "../../lib/AppHelper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { InputGroup } from "../../components/form/InputGroup";
import SampleHeader from "../../components/common/SampleHeader";
import { InputFile } from "../../components/common/InputFile";
import VerticalStepper from "../../components/common/VerticalStepper";
import ErrorDataTable from "../../components/common/ErrorDataTable";
import { UserCard } from "../UserCard";
import AbclSampleFile from "./AckoAbclSampleFile.xlsx";
import AckoSampleFile from "./AckoAbclSampleFile.xlsx";
import CareSampleFile from "./CareSampleFile.xlsx";
import LibertySampleFile from "./LibertySampleFile.xlsx";
import { policyExcelUploaderLimit } from "../../lib/constants";

export class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        file: "",
        uploadExcelDtoList: "",
        emailRecipients: "",
      },
      email: "",
      file: "",
      filename: "",
      errorDataRow: [],
      errorData: [],
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
      validate: false,
      errMsg: "",
      current: 0,
      totalStep: 2,
      toggleErrData: false,
      userEmail: "",
      reseller_id: getResellerId(),
      isDragging: false,
      reportingEmail: getReportingEmail(),
      vendorValue: "",
      vendorBenefit: "",
      vendorCodes: [],
      vendorCodeBenefit: [],
      sampleFile: AckoSampleFile,
      uniqueHeaders: "",
      parseErrorData: [],
    };
    this.fileRender = this.fileRender.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.validateHeader = this.validateHeader.bind(this);
    this.validateExcelData = this.validateExcelData.bind(this);
    this.onSubmitHandle = this.onSubmitHandle.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
  }

  getVendorCodes() {
    getVendorCodesJava().then((res) => {
      let vendorCode = [];
      if (res.code === 200) {
        for (
          let index = 0;
          index < res?.responseObject?.vendorCode?.length;
          index++
        ) {
          vendorCode[index] = {
            label: res?.responseObject?.vendorCode[index],
            value: res?.responseObject?.vendorCode[index],
          };
        }
        this.setState({ vendorCodes: vendorCode });
      }
    });
  }

  getVendorCodeBenefit() {
    getVendorCodeBenefitJava(this.state.vendorValue).then((res) => {
      let vendorCodeBenefits = [];
      if (res.code === 200) {
        for (
          let index = 0;
          index < res?.responseObject?.benefits.length;
          index++
        ) {
          let specification = res?.responseObject?.benefits[index]["code"];
          if (res?.responseObject?.benefits[index]["specification_name"]) {
            specification =
              specification +
              " (" +
              res?.responseObject?.benefits[index]["specification_name"] +
              ")";
          }
          vendorCodeBenefits[index] = {
            label: specification,
            value: res?.responseObject?.benefits[index]["code"],
          };
        }
        this.setState({ vendorCodeBenefit: vendorCodeBenefits });
      }
    });
  }

  // submit handle
  onSubmitHandle() {
    AppStore.dispatch({ type: "LOADING", loading: true });
    if (this.state.params.uploadExcelDtoList?.length > 0) {
      // json to xlsx
      const ws = XLSX.utils.json_to_sheet(this.state.params.uploadExcelDtoList);
      // remove blank row

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      const wbout = XLSX.write(wb, {
        bookSST: true,
        type: "binary",
        compression: true,
      });

      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

      const file1 = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // blob to xlsx file
      const file = new File([file1], this.state.filename, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      excelPolicyUploader(
        file,
        this.state.params.emailRecipients,
        this.state.vendorValue,
        this.state.vendorBenefit
      )
        .then((res) => {
          if (res.code === 200) {
            setMessage("Excel file uploaded successfully", () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              this.props.history.push("/");
            });
          } else {
            setError(res.message, () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              this.props.history.push("/");
            });
          }
        })
        .catch(() => {
          setError("Something went wrong", () => {
            AppStore.dispatch({ type: "LOADING", loading: false });
            this.props.history.push("/");
          });
        });
    } else {
      setError("no data found for the upload", () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  }

  // header match validation
  validateHeader = async () => {
    const { data, cols, uniqueHeaders } = this.state;
    this.setState({
      errorDataRow: [],
      toggleErrData: false,
    });
    const headerData = data[0]?.filter((item) => item !== "Error_Message");
    const uniqueHeadersData = uniqueHeaders?.filter(
      (item) => item.name !== "Error Message"
    );
    const header = headerData;
    const uniqueHeader = uniqueHeadersData;
    const headerLength = header.length;
    const headersLength = uniqueHeader.length;
    if (headerLength === headersLength) {
      for (let i = 0; i < headerLength; i++) {
        const headerName = header[i];
        const headerObj = uniqueHeader[i].name;
        if (headerName.toUpperCase() === headerObj.toUpperCase()) {
          cols.push(headerObj);
        } else {
          return `${headerName} is not match with ${headerObj}`;
        }
      }
      return true;
    } else {
      return "headers not match please download sample excel file for reference";
    }
  };

  validateExcelData = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { data } = this.state;
    data[0].push("Error_Message");
    const errDataRow = [data[0]];

    const succData = [];
    let errMessage = [];
    let parseErrorData = [];
    let errCount = 0;
    let errorCountIndex = 0;
    data.map((row, index) => {
      let headers = this.state.uniqueHeaders;
      if (index > 0) {
        let count = 0;
        let obj = {};
        headers.map((header, i) => {
          if (header.mandatory) {
            if (!row[i]) {
              errCount = 1;
              errMessage.push("please fill " + header.name);
            } else if (
              header.type &&
              header.type === "aplhaNumeric" &&
              /^[a-zA-Z0-9]+$/.test(row[i]) === false
            ) {
              errCount = 1;
              errMessage.push(
                "please fill valid Number or Alhpanumeric " + header.name
              );
            } else if (
              header.type &&
              header.type === "number" &&
              /^[0-9]+$/.test(row[i]) === false
            ) {
              errCount = 1;
              errMessage.push("please fill valid Number " + header.name);
            }
            // check dob is valid
            else {
              count++;
              Object.assign(obj, { [header.name]: row[i] });
            }
          } else {
            count++;
            Object.assign(obj, { [header.name]: row[i] });
          }
        });
        if (count === headers.length) {
          Object.assign(obj, { ["Error_Message"]: "" });
          succData.push(obj);
        }
        if (errCount === 1) {
          row[headers.length] = errMessage
            .map((err) => {
              return err;
            })
            .join(", ");
          errDataRow.push(row);
          errCount = 0;
          errMessage = [];
          Object.assign(obj, { ["Error_Message"]: row[headers.length] });
          parseErrorData.push(obj);
          errorCountIndex = errorCountIndex + 1;
        }
      }
      // count = 0;
    });
    const { params } = this.state;
    this.setState({
      parseErrorData: parseErrorData,
      errorDataRow: errDataRow,
    });
    this.setState({ params: { ...params, uploadExcelDtoList: succData } });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  //  xls file validation
  fileRender = async (file) => {
    /* Boilerplate to set up FileReader */
    let res = false;
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    // return promise function
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellDates: true,
          dateNF: "dd/mm/yyyy;@",
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
        });

        /* Update state */
        this.setState({ data: data });
        if (data.length > policyExcelUploaderLimit) {
          setError(
            `Excel file should not be more than ${
              policyExcelUploaderLimit - 1
            } rows`,
            () => {}
          );
          return resolve(false);
        } else if (data.length === 0) {
          setError("Excel file should not be empty", () => {});
          return resolve(false);
        } else {
          return resolve(true);
        }
      };
    });
  };

  onChangeHandle(e) {
    const { params } = this.state;
    AppStore.dispatch({ type: "BACKDROP", loading: false });
    AppStore.dispatch({ type: "LOADING", loading: true });
    const file = e?.dataTransfer?.files[0]
      ? e?.dataTransfer?.files[0]
      : e?.target?.files[0];
    // get file size
    const fileSize = file.size;

    const ext = file.name.split(".").pop();

    if (fileSize > 4100000) {
      setError("File size should not be more than 4 MB", () => {});
      // this.setState({ errMsg: "File size should be less than 4MB" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else if (ext === "xlsx") {
      this.fileRender(file).then((res) => {
        if (res) {
          this.setState({
            params: { ...params, file: file },
            filename: file.name,
            errMsg: "",
          });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
      });
    } else {
      setError("Please select only xlsx file", () => {});
      // this.setState({ errMsg: "Please select only xlsx file" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  }

  handleButtonTitle() {
    const { current } = this.state;
    if (current === 0 || current === 1 || current === 2 || current === 3) {
      return "Next";
    } else if (current === 4) {
      return "Upload";
    }
  }

  //Drag and Drop
  handleDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  }

  handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.onChangeHandle(event);
  }

  handleDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    // AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  }

  // create and download xlsx file from array of data
  downloadFile = () => {
    const { errorDataRow } = this.state;
    const ws = XLSX.utils.json_to_sheet(errorDataRow, {
      skipHeader: true,
      cellStyles: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    FileSaver.saveAs(data, "MismatchDataFile.xlsx");
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getVendorCodes();
  }

  resize() {
    if (window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  disabledHandler = () => {
    if (this.state.current === 0 && this.state.vendorValue) {
      return false;
    } else if (this.state.current === 1 && this.state.vendorBenefit) {
      return false;
    } else if (this.state.current === 2 && this.state.params.file) {
      return false;
    } else if (
      this.state.current === 3 &&
      this.state.params.file &&
      this.state.params.uploadExcelDtoList.length > 0
    ) {
      return false;
    } else if (this.state.current === 4 && this.state.params.file) {
      return false;
    } else {
      return true;
    }
  };

  onClickHandler = (e) => {
    const { current } = this.state;
    if (current == 0) {
      this.getVendorCodeBenefit();
      this.setState({ current: this.state.current + 1 });
    } else if (current == 1 || current == 3) {
      this.setState({ current: this.state.current + 1 });
    } else if (current == 4) {
      this.onSubmitHandle();
    }
    if (current == 2) {
      this.validateHeader().then((res) => {
        if (res == true) {
          this.validateExcelData().then((res) => {
            this.setState({ current: this.state.current + 1 });
          });
        } else {
          setError(res, () => {});
        }
      });
    }
  };

  emailChangeHandler = () => {
    if (validateEmail(this.state.email)) {
      // check if email is already present in the list
      if (
        this.state.params.emailRecipients
          .split(",")
          .indexOf(this.state.email) === -1
      ) {
        let email = this.state.params.emailRecipients;
        if (email.length > 0) {
          email = email + "," + this.state.email;
        } else {
          email = this.state.email;
        }
        this.setState({
          params: {
            ...this.state.params,
            emailRecipients: email,
          },
          email: "",
        });
      } else {
        this.setState({
          email: "",
        });
      }
    }
  };

  removeEmail = (index) => {
    let email = this.state.params.emailRecipients;
    let emailArray = email.split(",");
    emailArray.splice(index, 1);
    email = emailArray.join(",");
    this.setState({
      params: {
        ...this.state.params,
        emailRecipients: email,
      },
    });
  };

  render() {
    const { params, vendorCodes, vendorCodeBenefit, sampleFile } = this.state;
    const steps = [
      { title: "Select Vendor" },
      { title: "Select Benefit" },
      { title: "Upload Excel File" },
      { title: "Preparation" },
      { title: "Upload" },
    ];

    return (
      <Main
        MessageRef={this.MessageRef}
        onDrop={this.state.current === 2 && this.handleDrop}
        onDragOver={this.state.current === 2 && this.handleDragOver}
        onDragLeave={this.state.current === 2 && this.handleDragLeave}
        current={this.state.current}
      >
        <div className="w-full flex md:flex-row flex-col">
          <div className="w-full md:w-1/3">
            <AppBackButton to="/" />
          </div>
          <div className="w-full md:w-1/3 text-center mb-5">
            <AppHeading>Vendor Subscription Uploader</AppHeading>
          </div>
          <div className="w-full md:w-1/3 none md:block"></div>
        </div>

        <div className="flex h-96">
          <div className="mb-14">
            {this.state.isMobile ? (
              <VerticalStepper
                activeStep={this.state.current}
                steps={steps}
                isMobile={true}
              />
            ) : (
              <VerticalStepper
                activeStep={this.state.current}
                steps={steps}
                isMobile={false}
              />
            )}
          </div>
          <div style={{ width: "100%" }}>
            <div>
              {this.state.current === 0 && (
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      marginLeft: "13px",
                      marginTop: "30px",
                    }}
                  >
                    Please select a vendor:-
                  </p>
                  <div className="lg:flex">
                    {vendorCodes && vendorCodes.length > 0 ? (
                      vendorCodes.map((item, index) => (
                        <div style={{ width: "250px", textAlign: "center" }}>
                          <UserCard
                            key={index}
                            item={item}
                            valueField={this.state.vendorValue}
                            labelField={"label"}
                            selectedValue={this.state.vendorValue}
                            handleRadioChange={(e) => {
                              {
                                this.setState({
                                  vendorValue: e.target.value.value,
                                });
                                this.setState({ vendorCodeBenefit: [] });
                                this.setState({ vendorBenefit: "" });
                                switch (e.target.value.value) {
                                  case "LIBERTY":
                                    this.setState({
                                      sampleFile: LibertySampleFile,
                                    });
                                    this.setState({
                                      uniqueHeaders: LibertyHeaders,
                                    });
                                    break;
                                  case "ACKO":
                                    this.setState({
                                      sampleFile: AckoSampleFile,
                                    });
                                    this.setState({
                                      uniqueHeaders: AckoHeaders,
                                    });
                                    break;
                                  case "ABCL":
                                    this.setState({
                                      sampleFile: AbclSampleFile,
                                    });
                                    this.setState({
                                      uniqueHeaders: AbclHeaders,
                                    });
                                    break;
                                  case "CARE":
                                    this.setState({
                                      sampleFile: CareSampleFile,
                                    });
                                    this.setState({
                                      uniqueHeaders: CareHeaders,
                                    });
                                    break;
                                  default:
                                    this.setState({
                                      sampleFile: LibertySampleFile,
                                    });
                                }
                              }
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="flex flex-col justify-center items-center">
                        <p className="text-center text-gray-500">
                          No Vendor Code found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.current === 1 && (
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      marginLeft: "13px",
                      marginTop: "30px",
                    }}
                  >
                    Please select a vendor benefit:-
                  </p>
                  <div className="lg:flex">
                    {vendorCodeBenefit && vendorCodeBenefit.length > 0 ? (
                      vendorCodeBenefit.map((item, index) => (
                        <div style={{ width: "250px", textAlign: "center" }}>
                          <UserCard
                            key={index}
                            item={item}
                            valueField={this.state.vendorBenefit}
                            labelField={"label"}
                            selectedValue={this.state.vendorBenefit}
                            handleRadioChange={(e) => {
                              this.setState({
                                vendorBenefit: e.target.value.value,
                              });
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="flex flex-col justify-center items-center">
                        <p className="text-center text-gray-500">
                          No benefit found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.current === 2 && (
                <div>
                  <SampleHeader samplefile={sampleFile} />
                  <div className="flex flex-col justify-center items-center mt-10">
                    <label>
                      {!this.state.params.file ? "Upload File " : "File Name"}
                      <code className="text-red">*</code>
                    </label>
                    <div className="flex items-center justify-center bg-grey-lighter mt-5">
                      <label
                        className={`relative flex pl-3 items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-red ${
                          !this.state.filename ? "cursor-pointer" : ""
                        } hover:bg-red hover:text-white`}
                      >
                        {!this.state.filename ? (
                          <>
                            <InputFile
                              file={this.state.params.file}
                              onChangeHandler={this.onChangeHandle}
                            />
                          </>
                        ) : (
                          <>
                            <div className="m-1 mr-3 flex relative">
                              {this.state.filename.length > 30 ? (
                                <span className="text-sm">
                                  {this.state.filename.substring(0, 30)}...
                                </span>
                              ) : (
                                <span className="text-sm">
                                  {this.state.filename}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      </label>

                      {this.state.filename ? (
                        <span>
                          <XIcon
                            className="h-6 w-6 ml-3 text-red cursor-pointer"
                            onClick={() => {
                              this.setState({
                                filename: "",
                                params: {
                                  ...params,
                                  file: "",
                                },
                                errMsg: "",
                                data: [],
                              });
                            }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className="flex justify-center mt-2 "
                    style={{ fontWeight: "600" }}
                  >
                    Or Drop Excel here
                  </div>
                  <div className="flex justify-center mt-2">
                    {this.state.errMsg ? <span>{this.state.errMsg}</span> : ""}
                  </div>
                </div>
              )}

              {this.state.current === 3 && (
                <div className="flex flex-col space-y-6 border-2 p-3">
                  <p className="ml-4">
                    Total No. of data ready to be import :{" "}
                    <span className="text-green-500">
                      {this.state.params.uploadExcelDtoList.length}
                    </span>
                  </p>

                  {this.state.errorDataRow.length > 1 ? (
                    <>
                      <hr />
                      <div className="flex flex-wrap justify-between mt-3 items-center p-1 pr-2 pl-2 rounded-md">
                        <div
                          className="flex cursor-pointer"
                          onClick={() =>
                            this.setState({
                              toggleErrData: !this.state.toggleErrData,
                            })
                          }
                        >
                          <ChevronRightIcon
                            className={`h-5 w-6 pt-1 cursor-pointer text-red ${
                              this.state.toggleErrData
                                ? "transform rotate-90 duration-200"
                                : "transform rotate-0 duration-200"
                            }`}
                          />

                          <p>
                            Total No. of data skipped :{" "}
                            <span className="text-yellow-400">
                              {this.state.parseErrorData?.length}
                            </span>
                          </p>
                        </div>
                        <div>
                          <span className="flex">
                            Download Unmatched Data Excel File{" "}
                            <DownloadIcon
                              onClick={this.downloadFile}
                              className=" h-5 w-5 text-red ml-2 cursor-pointer"
                            />
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.toggleErrData ? (
                    <ErrorDataTable
                      data={this.state.parseErrorData}
                      header={this.state.uniqueHeaders}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>

            {this.state.current === 4 && (
              <>
                By default messages are sent to the users email{" "}
                {<span className="text-blue-500">{this.state.userEmail}</span>}.
                You can also send them to a specific email address.
                <div className="flex mt-4">
                  <InputGroup
                    label="Email"
                    placeholder="Enter email..."
                    attrs={{
                      maxLength: 64,
                      value: this.state.email,
                      onChange: (e) => {
                        this.setState({
                          email: e.target.value,
                        });
                      },
                    }}
                    validateError={(value) => {
                      if (validateEmail(value)) {
                        return false;
                      } else {
                        return "please enter correct email ID";
                      }
                    }}
                  />
                  <Button
                    className="m-2 mt-7"
                    outline={true}
                    title={"Add"}
                    attrs={{
                      onClick: this.emailChangeHandler,
                    }}
                  />
                </div>
                {this.state.params.emailRecipients?.length > 0 && (
                  <div
                    style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}
                  >
                    {
                      // comma separated list of email recipients
                      this.state.params.emailRecipients
                        .split(",")
                        .map((item, index) => (
                          <div className="flex" key={index}>
                            <span className="text-sm">{item}</span>
                            <TrashIcon
                              className="h-5 w-6 text-red cursor-pointer"
                              onClick={() => {
                                this.removeEmail(index);
                              }}
                            />
                          </div>
                        ))
                    }
                  </div>
                )}{" "}
                {this.state.reportingEmail &&
                  this.state.reportingEmail.length > 0 && (
                    <div
                      style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}
                    >
                      {" "}
                      <span className="text-sm">
                        {this.state.reportingEmail}
                      </span>
                    </div>
                  )}
              </>
            )}

            <div className="relative md:mr-10 flex justify-around mt-10 bottom-0 mb-6 right-0">
              {this.state.current > 0 && (
                <Button
                  title="Previous"
                  outline={true}
                  attrs={{
                    onClick: () => {
                      if (this.state.current > 0) {
                        this.setState({
                          current: this.state.current - 1,
                        });
                      }
                      if (this.state.current === 1) {
                        this.setState({
                          filename: "",
                          file: "",
                          params: {
                            ...params,
                            file: "",
                          },
                          current: this.state.current - 1,
                          vendorBenefit: "",
                        });
                      } else if (this.state.current === 2) {
                        this.setState({
                          filename: "",
                          file: "",
                          params: {
                            ...params,
                            file: "",
                          },
                          current: this.state.current - 1,
                        });
                      }
                    },
                  }}
                />
              )}

              <Button
                outline={true}
                title={this.handleButtonTitle()}
                attrs={{
                  onClick: this.onClickHandler,
                  disabled: this.disabledHandler(),
                }}
              />
            </div>
            {this.state.current === 2 ? (
              <div className="border-2 m-3 mt-10">
                <span className="flex m-2 text-red-500">Notes -</span>
                <ul className="list-disc list-inside m-2">
                  <li>
                    <span className="text-sm justify-center">
                      The file should be in xlsx format.
                    </span>
                  </li>
                  <li>
                    <span className="text-sm text-right">
                      The file should have the same structure as the sample
                      file.
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reseller_id: state.reseller_id,
  };
};

export default withRouter(connect(mapStateToProps)(Index));
